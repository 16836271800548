import { Box, Container, Typography } from "@mui/material";
import React from "react";
import './privacy.css';
function PrivacyPolicy() {
  return (
    <>
	{/* <Container maxWidth={"xl"}> */}
		<div className="privacy-container">
        <Box
          sx={{
            display: "flex",
            flexDirection:'column',
            minHeight: "20vh",
            width: "100%",
            margin:'10px 0'   
          }}
        >
          <Box
            sx={{
              minHeight: "15vh",
              width: "100%",
              backgroundColor: "#DDDDDD",
              fontSize: "13px",
            }}
          >
            <Typography variant={'h4'}>Privacy Policy</Typography>
          </Box>
          <Typography sx={{minHeight:'5vh',width:'100%'}}>
            leedoit-signage is a new Digital Out Of Home advertising service
            offered by leedoit-signage. Throughout this document, we use the
            terms “leedoit-signage”, “We”, “us”, “our” interchangeably to refer
            to leedoit-signage, and its services which include our website, its
            dashboard and mobile application & token website and platform.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={'h6'} sx={{ color: "#2F8ECC" }}>Agreement to Terms</Typography>
          <Typography>
            By accessing and using our app and website, or any of
            leedoit-signage services, you agree to these Terms and our Privacy
            Policy. If you don’t agree to these Terms & Privacy Policy, you
            cannot access or use our service. By accessing and using our app and
            website, or any of leedoit-signage services, you agree to these
            Terms and our Privacy Policy. If you don’t agree to these Terms &
            Privacy Policy, you cannot access or use our service.
            <br />
            <br />
            These Terms will remain in effect until the User Account has been
            closed and all of the User’s content will remain the property of
            leedoit-signage. These Terms will remain in effect until the User
            Account has been closed and all of the User’s content will remain
            the property of leedoit-signage.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={'h6'} sx={{ color: "#2F8ECC" }}>Change to Terms</Typography>
          <Typography>
            These Terms are subject to change at any time for any reason, such
            as changes in the law and changes in technology, or at the
            leedoit-signage team’s discretion. User is responsible for regularly
            reviewing the Terms and Privacy Policy that appear on the Website
            and apps. Terms and Privacy Statement are effective immediately upon
            being posted on Website and apps and those User Terms and Privacy
            Statement bind User immediately upon posting. These Terms are
            subject to change at any time for any reason, such as changes in the
            law and changes in technology, or at the leedoit-signage team’s
            discretion. User is responsible for regularly reviewing the Terms
            and Privacy Policy that appear on the Website and apps. Terms and
            Privacy Statement are effective immediately upon being posted on
            Website and apps and those User Terms and Privacy Statement bind
            User immediately upon posting.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={'h6'} sx={{ color: "#2F8ECC" }}>
            What does this Privacy Policy cover?
          </Typography>
          <Typography>
            Leedoit-signage ( “leedoit-signage”, “we” or “us”) privacy policy
            describes the privacy practices of leedoit-signage and
            leedoit-signage Website and app(s), as well as our products, online
            services, and apps that include a link to this policy (we refer to
            our products, online services, and apps as “applications”). This
            privacy policy also applies to leedoit-signage marketing and
            advertising practices. It applies (i) immediately to users who sign
            in to the app. Any person who does not agree to be bound by this
            Privacy Policy should not create a user account or interact with the
            Website and app.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={'h6'} sx={{ color: "#2F8ECC" }}>App Permissions</Typography>
          <Typography>
            We gather certain information either automatically or based on
            permission settings in our mobile applications. Such information
            includes, but is not limited to, the model of mobile device you use,
            your device location, the mobile network (mobile carrier) you use,
            your mobile network information, language, and Wi-Fi network
            information. Based on the permission level you grant us, such
            information may be collected both when you are using our mobile
            applications and when the mobile applications are closed.
          </Typography>
        </Box>

        <Box
          sx={{
            minHeight: "10vh",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            // border: "1px solid black",
            margin: "20px 0",
            alignItems: "flex-start",
          }}
        >
          <Typography variant={'h6'} sx={{ color: "#2F8ECC" }}>
            Changes to Our Privacy Policy
          </Typography>
          <Typography>
            We reserve the right to make changes to this policy from time to
            time, to take into consideration changes to our standard practices
            and procedures or where necessary to comply with new laws and
            regulations. The latest version of this policy will always be
            available on our website and we encourage to review it regularly.
            Any users of our Products/ Services maintain personal responsibility
            to review our up to date Privacy Policy & to take note of any
            material changes that may be notified to customers by means other
            than our website (such as email).
          </Typography>
        </Box>
		</div>
      {/* </Container> */}
    </>
  );
}

export default PrivacyPolicy;
