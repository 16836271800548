import axios from 'axios';
// export const baseApi='http://localhost:5000/api';
// export const imgUrl=`http://localhost:5000`;
export const baseApi='https://signage.digntec.com/api';
export const imgUrl='https://signage.digntec.com'

export const api = axios.create({
    baseURL:baseApi,
    withCredentials:true,
    // headers:{
    //     'Content-type':['multipart/form-data','application/json'],
    //     Accept:'application/json'
    // }
})