import {  FormControl,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    Box
     } from '@mui/material';
import React, { useEffect,useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch ,useSelector} from 'react-redux';
import toast from "react-hot-toast";
import { changePassword, userFlagOff } from '../../Redux/Actions/userAction';
function ChangePassword() {
    const dispatch=useDispatch();
    const {isPasswordChanging,isPasswordChanged,isTimeOver,done}=useSelector(state=>state.User);
    const params=useParams();
    const [password,setPassword]=useState('');
    const [type, setType] = useState(true);
    useEffect(()=>{
      if(isPasswordChanged){
          toast.success('Password have been changed!');
          dispatch(userFlagOff());
      }
      if(isTimeOver){
          toast.error('Time is over, please send again request.');
          dispatch(userFlagOff());
      }
    },[isPasswordChanged,isTimeOver])
    const submit=()=>{
         dispatch(changePassword({
            id:params.id,
            token:params.token,
            password
        }))
    }
  return (
    <>
     <div className="container">
     {
            isPasswordChanging && <>
            <Box sx={{
             position:'absolute',
             height:'100vh',
             width:'100vw',
             background:'rgba(0,0,0,0.5)',
             top:0,
             left:0,
             right:0,
             bottom:0,
             zIndex:'1000000',
             display:'flex',
             justifyContent:'center',
             alignItems:'center'
           }}>
             <img src="/images/spiner.gif"/>
           </Box>
            </>
          }
        <div className="eclips-1"></div>
        <div className="eclips-2"></div>
        <div className="eclips-3"></div>
        <div className="inner-container">
          <div className="top-box">
            <div className="login-box">
              <div className="forget-password-form">
                <div className="login-form-header">
                  <div className="logo-sec">
                    <div className="logo">
                      <img src="/images/LEEDOIT.png" />
                    </div>
                  </div>
                </div>
                {
                    done ? <>
                    <div className="title">
                    <div className="login-icon"></div>
                    <div className="title-text">Password Changed.</div>
                  </div>
                  <div className='other-controlls'>
                  <Link to={'/login'}>Go to login</Link>
                  </div>
                    
                    </> :
                     <>
                    <div className="login-form-body">
                  <div className="title">
                    <div className="login-icon"></div>
                    <div className="title-text">Reset Password</div>
                  </div>
                  <div className="input-fields">
                  <FormControl variant={"standard"} fullWidth>
                        <InputLabel htmlFor="password">New Password</InputLabel>
                        <Input
                          id="password"
                          type={type ? "password" : "text"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment
                              position={"end"}
                              style={{ marginTop: "-10px", color: "#551fff" }}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setType(!type)}
                              >
                                {type ? (
                                  <VisibilityOffIcon
                                    style={{ color: "#551fff" }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    style={{ color: "#551fff" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                  </div>
                  <div className="button-field">
                    <button type={'button'} className="btn-login" onClick={submit}>
                      send
                    </button>
                  </div>
                  <div className="resend-email flex justifyContentCenter alignItemCenter">
                      Resend <Link to={'/forgetpassword'}>Back to Forgetpassword</Link>
                  </div>
                </div> 
                    </>
                }
               

              </div>
            </div>
            <div className="text-box">
              <div className="text-block">
                <p className="main-heading">We Make Your Business Digitalize</p>
                <p className="sub-heading">
                    If you have question please contact support@leedoit.com?
                  </p>
              </div>
            </div>
          </div>
          {/* <div className="bottom-box"></div> */}
        </div>
      </div>
    </>
  )
}

export default ChangePassword