import React, { useState, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import "./playlist.css";
import SearchIcon from "@mui/icons-material/Search";
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Box,
  Checkbox,
  Typography,
  FormControl,
  InputLabel,
  Input,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import PlaylistCard from "./component/playlist-card/PlaylistCard";
import { useDispatch, useSelector } from "react-redux";
import {
  addPlaylist,
  deletePlaylist,
  deleteContentFromPlaylist,
  updateDuration,
  getPlaylist,
  playlistFlagOff,
  updatePlaylist,
  deletePlaylistSchedule
} from "../../Redux/Actions/playlistAction";
// import { DeleteOutline } from "@mui/icons-material";
// import { imgUrl } from "../../api/api";
import Tab from "../tab/Tab";
import PrimarySearchBar from "../../components/searchBar/searchbar";
// import DeleteIcon from '../../Assets/images/delete.svg';
// import { ReactComponent as DeleteIcon } from "../../Assets/images/delete.svg";
import { getScreens } from "../../Redux/Actions/screenAction";
import toast from "react-hot-toast";
import PlaylistContentCardItem from "./component/content-card/PlaylistContentCardItem";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import dayjs from "dayjs";
import { getContent } from "../../Redux/Actions/contentAction";
// import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
function Playlist({ user, socketRef }) {
  
  const dispatch = useDispatch();
  const {
    playlist,
    content,
    isPlaylistAdded,
    isPlaylistDeleted,
    isPlaylistUpdated,
    isPlaylistLoading,
    isPlaylistScheduled,
    isPlaylistScheduledDeleted,
  } = useSelector((state) => state.Playlist);
  const [value, setValue] = useState(dayjs("2014-08-18T21:11:54"));
  const { screens } = useSelector((state) => state.Screen);
  const [openModel, setOpenModel] = useState(false);
  const [openContentModel, setOpenContentModel] = useState(false);
  const [name, setName] = useState("");
  const [pId, setPID] = useState("");
  const [screenMac, setScreenMac] = useState("");
  const [playlistId, setPlaylistId] = useState("");
  const [contentId, setContentId] = useState("");
  const [openDeleteModel, setOpenDeleteModel] = useState(false);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [openTimeDialoge, setOpenTimeDialoge] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [textVal, setTextVal] = useState("");
  const [filteredPlaylist, setFilteredPlaylist] = useState([]);
  const [contentID,setContentID]=useState('');
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [day, setDay] = useState([]);
  const [isScheduled, setIsScheduled] = useState(false);
  const [scheduleInfo, setScheduleInfo] = useState({});
  const [days, setDays] = useState([
    { _id: 1, day: "Mon", dayChecked: false },
    { _id: 2, day: "Tue", dayChecked: false },
    { _id: 3, day: "Wed", dayChecked: false },
    { _id: 4, day: "Thu", dayChecked: false },
    { _id: 5, day: "Fri", dayChecked: false },
    { _id: 6, day: "Sat", dayChecked: false },
    { _id: 7, day: "Sun", dayChecked: false },
  ]);
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    // const arr1 = [1, 2, 3, 4];
    // const arr2 = [3, 4, 5, 6];

    // const arr3 = arr1.map((el) => {
    //   const index = arr2.indexOf(el);
    //   return index !== -1 ? arr2[index] : el;
    // });

    // console.log(arr3); // Output: [1, 2, 5, 6]
    socketRef.current.emit('leaving--connection')
    dispatch(getPlaylist(user?._id));
    dispatch(getScreens(user?._id));
    dispatch(getContent(user?._id));
  }, []);
  useEffect(() => {
    if (isPlaylistAdded) {
      toast.success("Playlist added successfully!");
      setIsScheduled(false);
      setFilteredPlaylist([...playlist]);
      dispatch(playlistFlagOff());
    }
    if (isPlaylistDeleted) {
      toast.success("Deleted successfully!");
      setIsScheduled(false);
      setFilteredPlaylist([...playlist]);
      dispatch(playlistFlagOff());
    }
    if (isPlaylistUpdated) {
      toast.success("Updated successfully!");
      setIsScheduled(false);
      setFilteredPlaylist([...playlist]);
      dispatch(playlistFlagOff());
    }
    if (isPlaylistScheduled) {
      toast.success("Playlist have scheduled");
      setIsScheduled(false);
      const _checkingScreenAssing = screens.find(
        (s) => s.playlist?._id === playlistId
      );
      if (_checkingScreenAssing !== undefined) {
        emitOnScheduleAdd(_checkingScreenAssing.mac);
      }
      dispatch(playlistFlagOff());
    }
    if (isPlaylistScheduledDeleted) {
      setOpenSchedule(false);
      toast.success("schedule deleted successfully");
      setIsScheduled(false);
      const __checkingScreenAssing = screens.find(
        (s) => s.playlist?._id === playlistId
      );
      if (__checkingScreenAssing !== undefined) {
        emitOnScheduleAdd(__checkingScreenAssing.mac);
      }
      dispatch(playlistFlagOff());
    }
  }, [
    isPlaylistAdded,
    isPlaylistDeleted,
    isPlaylistUpdated,
    isPlaylistScheduled,
    isPlaylistScheduledDeleted,
  ]);
  const submit = () => {
    dispatch(
      addPlaylist({
        name,
        user: user?._id,
        subuser: user?._id,
        role: user?.role,
      })
    );
    setOpenModel(false);
  };
  const addContentModel = (id, mac) => {
    setPID(id);
    // here i checking screen assign to playlist or not
    const checkingScreenAssingnation = screens.find(
      (s) => s.playlist?._id === id
    );
    // console.log("from add content model", checkingScreenAssingnation);
    if (checkingScreenAssingnation !== undefined) {
      console.log(checkingScreenAssingnation);
      setScreenMac(checkingScreenAssingnation.mac);
      setOpenContentModel(true);
    } else {
      setOpenContentModel(true);
    }
  };
  const deleteWholePlaylist = (id, mac) => {
    setPlaylistId(id);
    const checkingScreenAssingnation = screens.find(
      (s) => s.playlist?._id === id
    );
    if (checkingScreenAssingnation !== undefined) {
      setScreenMac(checkingScreenAssingnation.mac);
      setOpenDeleteModel(true);
    } else {
      setOpenDeleteModel(true);
    }
  };
  const emitOfDeletePlaylist = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("playlist_deleted", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const confirmDeletePlaylist = () => {
    dispatch(deletePlaylist(playlistId));
    if (screenMac !== "") {
      emitOfDeletePlaylist(screenMac);
    }
    setOpenDeleteModel(false);
  };
  const emitOfDeleteContent = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("content_deleted", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const deleteContent = (playlistid, contentid, screenid) => {
    console.log("delete=>playlistid,contentid=>",playlistid,contentid)
    dispatch(
      deleteContentFromPlaylist({
        playlistId: playlistid,
        contentId: contentid,
      })
    );
    const checkingScreenAssing = screens.find(
      (s) => s.playlist?._id === playlistid
    );
    if (checkingScreenAssing !== undefined) {
      emitOfDeleteContent(checkingScreenAssing.mac);
    }
  };
  const updateTimeHandler = (playlistid, contentid, duration, mac) => {
    setOpenTimeDialoge(true);
    setPlaylistId(playlistid);
    setContentId(contentid);
    // console.log("playlistid=>", playlistid);
    const checkingScreenAssing = screens.find(
      (s) => s?.playlist?._id === playlistid
    );
    // console.log("screen=>", checkingScreenAssing);
    if (checkingScreenAssing !== undefined) {
      setScreenMac(checkingScreenAssing.mac);
    }
    setSec(Math.floor((duration / 1000) % 60));
  };
  const emitOnUpdateDuration = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("update_duration", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const updateTimeConfirm = () => {
    const obj = {
      playlistId,
      contentId,
      time: `${min}:${sec}`,
    };
    setOpenTimeDialoge(false);
    dispatch(updateDuration(obj));
    setMin(0);
    setSec(0);
    if (screenMac !== "") {
      emitOnUpdateDuration(screenMac);
    }
  };
  const searchHandler = (e) => {
    setTextVal(e);
    if (e !== "") {
      const searched = playlist.filter((p) => {
        return Object.values(p)
          .join("")
          .toLocaleLowerCase()
          .includes(e.toLocaleLowerCase());
      });
      setFilteredPlaylist([...searched]);
    } else {
      setFilteredPlaylist([...playlist]);
    }
  };
  const addSchedule = (playlistid,contentid) => {
    // console.log(playlistid,contentid)
    const info = playlist.find((p) => p._id === playlistid);
    const content=info?.content?.find(c=>c._id===contentid);
   console.log(info,content)
    const __startDate = new Date(content?.schedule?.startDate)
      .toLocaleDateString()
      .split("/");
    const __endDate = new Date(content?.schedule?.endDate)
      .toLocaleDateString()
      .split("/");
    const isStartDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __startDate[0]
    );
    const isEndDateOneDigit = [1, 2, 3, 4, 5, 6, 7, 8, 9].find(
      (digit) => digit == __endDate[0]
    );
    const _startDate = `${__startDate[2]}-${
      isStartDateOneDigit !== undefined
        ? "0" + isStartDateOneDigit
        : __startDate[0]
    }-${__startDate[1]}`.toString();
    const _endDate = `${__endDate[2]}-${
      isEndDateOneDigit !== undefined ? "0" + isEndDateOneDigit : __endDate[0]
    }-${__endDate[1]}`.toString();
    if (content?.schedule?.scheduled) {
      setIsScheduled(true);
      // console.log("inside condition=>", info);
    }
    setPlaylistId(playlistid);
    setContentID(contentid)
    setStartDate(_startDate);
    setEndDate(_endDate);
    setStartTime(content?.schedule?.startTime);
    setEndTime(content?.schedule?.endTime);
    setOpenSchedule(true);
    const dbDay = content?.schedule?.days;
    // console.log(dbDay)
    const sameArray = days.filter(
      (item) => !dbDay.find((d) => d.day === item.day)
    );
    // console.log([...sameArray,...dbDay]);
    setDays([...sameArray, ...dbDay]);
    setDay([...dbDay]);
  };

  const saveSchedule = () => {
    // console.log(day,playlistId,contentID);
    dispatch(
      updatePlaylist(playlistId,contentID, {
        startDate,
        endDate,
        startTime,
        endTime,
        days: day
      })
    );
    setDay([]);
    setOpenSchedule(false);
  };
  const deleteScheduleFromPlaylist = (pid,cid) => {
    dispatch(deletePlaylistSchedule(pid,cid));
  };
  const emitOnScheduleAdd = (mac) => {
    const id = setTimeout(() => {
      socketRef.current?.emit("update_duration", { detail: { mac: mac } });
      clearTimeout(id);
    }, 2000);
  };
  const cancelSchedule = () => {
    setOpenSchedule(false);
    // console.log([...days]);
    setDay([]);
    setDays([
      { _id: 1, day: "Mon", dayChecked: false },
      { _id: 2, day: "Tue", dayChecked: false },
      { _id: 3, day: "Wed", dayChecked: false },
      { _id: 4, day: "Thu", dayChecked: false },
      { _id: 5, day: "Fri", dayChecked: false },
      { _id: 6, day: "Sat", dayChecked: false },
      { _id: 7, day: "Sun", dayChecked: false },
    ]);
  };
  const handleDayCheckbox = (e) => {
    const { name, checked, value } = e.target;
    // console.log(name,checked,value,days)
    if (checked) {
      setDay((prev) => [...prev, { day: value, dayChecked: true }]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: true };
      setDays([...days]);
      // console.log(selectedDayIndex)
    } else {
      setDay((prev) => [...day.filter((d) => d.day !== value)]);
      const selectedDayIndex = days.findIndex((d) => d._id == name);
      days[selectedDayIndex] = { ...days[selectedDayIndex], dayChecked: false };
      // console.log(selectedDayIndex)
      setDays([...days]);
    }
    // console.log('days array=>',days)
  };
  return (
    <>
      <Layout title={"Playlist"} user={user}>
        <div className="parent-container">
          <div className="child-container">
            <Grid container>
              <Box
                sx={{
                  background: "white",
                  borderRadius: "16px",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  width: "100%",
                }}
              >
                <Grid lg={8}>
                  <Box
                    sx={{
                      background: "white",
                      padding: "33px 0",
                      marginRight: "20px !important",
                      borderRadius: "16px",
                    }}
                  >
                    <PrimarySearchBar
                      value={textVal}
                      onChange={searchHandler}
                    />
                  </Box>
                </Grid>
                <Grid lg={4} display={"flex"} justifyContent={"flex-end"}>
                  <div className="user-add-btn">
                    <button onClick={() => setOpenModel(true)}>
                      Create Playlist
                    </button>
                  </div>
                </Grid>
              </Box>
            </Grid>
          </div>

          <Grid container>
            <div className="playlist-container">
              {isPlaylistLoading ? (
                <>
                  <img
                    src="/images/spiner.gif"
                    style={{ height: "200px", width: "200px" }}
                  />
                </>
              ) : filteredPlaylist.length > 0 ? (
                filteredPlaylist.map((play) => (
                  <>
                    <PlaylistCard
                      play={play}
                      socketRef={socketRef}
                      addContentModel={addContentModel}
                      deleteWholePlaylist={deleteWholePlaylist}
                      addSchedule={addSchedule}
                    >
                      {play.content.map((v,index) => (
                       <PlaylistContentCardItem 
                       key={index}
                       content={v}
                       deleteContent={deleteContent}
                       addSchedule={addSchedule}
                       updateTimeHandler={updateTimeHandler}
                       playID={play._id}
                       />
                      ))}
                    </PlaylistCard>
                  </>
                ))
              ) : (
                playlist.map((play) => (
                  <>
                    <PlaylistCard
                      play={play}
                      socketRef={socketRef}
                      addContentModel={addContentModel}
                      deleteWholePlaylist={deleteWholePlaylist}
                      addSchedule={addSchedule}
                    >
                      {play.content.map((v,index) => (
                         <PlaylistContentCardItem 
                         key={index}
                         content={v}
                         deleteContent={deleteContent}
                         addSchedule={addSchedule}
                         updateTimeHandler={updateTimeHandler}
                         playID={play._id}
                         />
                      ))}
                    </PlaylistCard>
                  </>
                ))
              )}
            </div>
          </Grid>
        </div>
      </Layout>

      <Dialog
        sx={{ zIndex: "10000000000000" }}
        open={openSchedule}
        onClose={() => setOpenSchedule(false)}
        maxWidth={"lg"}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "30px",
            // paddingBottom: '18px',
            borderBottom: "3px solid #551FFF",
          }}
        >
          <ScheduleSendIcon sx={{ color: "#551fff" }} /> Schedule
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              height: "50vh",
              width: "30vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                height: "97%",
                width: "97%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-date">Start Date</InputLabel>
                  <TextField
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    id={"start-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-date">End Date</InputLabel>
                  <TextField
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    id={"end-date"}
                    type={"date"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  height: "20%",
                  width: "100%",
                  display: "flex",
                  gap: "10px",
                  border: "1px solid transparent",
                }}
              >
                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="start-time">Start Time</InputLabel>
                  <TextField
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                    id={"start-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>

                <Box sx={{ flex: 1 }}>
                  <InputLabel htmlFor="end-time">End Time</InputLabel>
                  <TextField
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                    id={"end-time"}
                    type={"time"}
                    fullWidth
                  />
                </Box>
              </Box>

              <Box
                id={"days-id"}
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                {days.map((d, index) => {
                  return (
                    <>
                      <input
                        onChange={handleDayCheckbox}
                        name={d._id}
                        value={d.day}
                        checked={d.dayChecked ? true : false}
                        type={"checkbox"}
                        id={`day-${index}`}
                        style={{ display: "none" }}
                      />
                      <label
                        htmlFor={`day-${index}`}
                        style={{
                          cursor: "pointer",
                          borderRadius: "10px",
                          height: "50px",
                          width: "80px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          border: "1px solid black",
                        }}
                      >
                        {d.day}
                      </label>
                    </>
                  );
                })}
              </Box>

              <Box
                sx={{
                  minHeight: "20%",
                  margin: "10px 0",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  gap: "5px",
                }}
              >
                <Button
                  variant={"outlined"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={cancelSchedule}
                >
                  Cancel
                </Button>
                <Button
                  variant={"contained"}
                  type="button"
                  sx={{
                    width: "100px",
                  }}
                  onClick={saveSchedule}
                >
                  Save
                </Button>
                {isScheduled ? (
                  <>
                    <Button
                      variant="contained"
                      color={"error"}
                      onClick={() => deleteScheduleFromPlaylist(playlistId,contentID)}
                    >
                      Delete
                    </Button>
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={"xl"}
        sx={{
          maxWidth: "100%",
          zIndex: "1000000000000",
        }}
        open={openModel}
        onClose={() => setOpenModel(false)}
      >
        <DialogTitle
          style={{
            textAlign: "center",
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "30px",
            // paddingBottom: '18px',
            borderBottom: "3px solid #551FFF",
          }}
        >
          Create Playlist
        </DialogTitle>
        <DialogContent>
          <div className="create-playlist-model flex flex-column">
            <img src={"/images/PlaylistCreate.png"} />
            <div className="create-playlist-input-field">
              <TextField
                variant={"standard"}
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Type Playlist Name"
                fullWidth
              />
            </div>
            <div
              style={{
                marginTop: "30px",
              }}
              className="create-playlist-btn-group flex justifyContentCenter alignItemCenter"
            >
              <button
                onClick={() => setOpenModel(false)}
                className="btn-cancel"
              >
                Cancel
              </button>
              <button onClick={submit} className="btn-save">
                Save
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        maxWidth={"xl"}
        open={openContentModel}
        onClose={() => setOpenContentModel(false)}
        sx={{
          zIndex: "10000000000",
        }}
      >
        <DialogTitle
          style={{ borderBottom: "5px solid #551fff", textAlign: "center" }}
        >
          Add Content
        </DialogTitle>
        <DialogContent>
          <Tab
            pId={pId}
            screenMac={screenMac}
            socketRef={socketRef}
            close={(a) => setOpenContentModel(a)}
          />
          {/* <div className="main-tab-container">
            
          </div> */}
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{
          // maxWidth: '20vw',
          // height: '30vh'
          zIndex: "100000000",
        }}
        open={openTimeDialoge}
        onClose={() => setOpenTimeDialoge(false)}
      >
        <DialogTitle
          sx={{
            fontWeight: "700",
            fontSize: "18px",
            borderBottom: "2px solid #551FFF",
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Time Duration
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                color: "#92959E",
              }}
            >
              Are you sure to Update Time?
            </Typography>

            <Box sx={{ display: "flex", mt: 5, width: "100%" }}>
              <TextField
                variant={"standard"}
                type="number"
                value={sec}
                onChange={(e) => setSec(e.target.value)}
                fullWidth
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                mt: 5,
              }}
            >
              <button
                style={{
                  background: "white",
                  padding: "8px",
                  width: "130px",
                  color: "#551FFF",
                  border: "1px solid #551FFF",
                  marginRight: "10px",
                  borderRadius: "5px",
                }}
                onClick={() => setOpenTimeDialoge(false)}
                className="btn danger"
              >
                No
              </button>
              <button
                style={{
                  background: "#551FFF",
                  padding: "8px",
                  width: "130px",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                }}
                onClick={updateTimeConfirm}
                className="btn primary"
              >
                Update
              </button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        sx={{ zIndex: "1000000" }}
        open={openDeleteModel}
        onClose={() => setOpenDeleteModel(false)}
      >
        <DialogTitle style={{ textAlign: "center" }}>Confirmation</DialogTitle>
        <DialogContent style={{ borderRadius: "50px" }}>
          <div
            style={{
              display: "flex",
              gap: "2px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Do you want to Delete?</h3>
            <div className="btn-group flex">
              <button
                onClick={() => setOpenDeleteModel(false)}
                className="btn-cancel"
              >
                Cancel
              </button>
              <button onClick={confirmDeletePlaylist} className="btn-save">
                Yes
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Playlist;
