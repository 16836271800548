import {
  ADD_PLAYLIST_TO_SCREEN,
  ADD_SCREEN_REQUEST,
  ADD_SCREEN_SUCCESS,
  DELETE_SCREEN,
  FIND_ONE_SCREEN,
  SCREEN_FLAG_OFF,
  SCREEN_REQUEST,
  SCREEN_REQUEST_FAILED,
  SCREEN_REQUEST_SUCCESS,
  SCREEN_STATUS_ACTIVE,
  SCREEN_STATUS_DEACTIVE,
  UNCHECK_ASSIGNED_SCREEN,
} from "../constant";
const initial = {
  screens: [],
  screen:{},
  screenLoading: false,
  isScreenAdded: false,
  isScreenDeleted:false,
  isAdded: false,
  isUnChecked:false,
  mac:''
};
export const screenReducer = (state = initial, action) => {
  switch (action.type) {
    case ADD_SCREEN_REQUEST:
      return {
        ...state,
        isScreenAdded: true,
      };
    case ADD_SCREEN_SUCCESS:
      return {
        ...state,
        isScreenAdded: false,
        isAdded: true,
        screens: [...state.screens, action.payload],
        mac:action.payload.mac
      };
    case SCREEN_REQUEST:
      return {
        ...state,
        screenLoading: true,
      };
    case SCREEN_REQUEST_SUCCESS:
      let newScreen=[]
      action.payload.forEach(s=>{
        newScreen.push({...s,screenAssigned:true})
      })
      return {
        ...state,
        screenLoading: false,
        screens: newScreen,
      };
      case SCREEN_REQUEST_FAILED:
        return {
          ...state,
          screenLoading:false
        }
        case FIND_ONE_SCREEN:
          return {
            ...state,
            screen:{...state.screens.find(s=>s._id===action.payload),screenAssigned:false}
          }
        case ADD_PLAYLIST_TO_SCREEN:
          const assingnedScreenIndex=state.screens.findIndex(s=>s._id===action.payload._id);
          state.screens[assingnedScreenIndex]={...action.payload,screenAssigned:false}
          return {
            ...state,
            screens:state.screens,
            isUnChecked:true
          }
          case UNCHECK_ASSIGNED_SCREEN:
            console.log(action.payload)
            const findUnCheckScreen=state.screens.findIndex(s=>s._id===action.payload)
            state.screens[findUnCheckScreen]={...state.screens[findUnCheckScreen],screenAssigned:true}
            return {
              ...state,
              screens:state.screens,
              isUnChecked:true
            }
            case SCREEN_FLAG_OFF:
              return {
                ...state,
                isUnChecked:false,
                isAdded:false,
                isScreenDeleted:false
              }
    case SCREEN_STATUS_ACTIVE:
      const activeStatusIndex=state.screens.findIndex(s=>s._id===action.payload._id)
      state.screens[activeStatusIndex]={...action.payload}
      return {
        ...state,
        screens: state.screens,
      };
    case SCREEN_STATUS_DEACTIVE:
      const DeactiveStatusIndex=state.screens.findIndex(s=>s._id===action.payload._id)
      state.screens[DeactiveStatusIndex]={...action.payload}
      return {
        ...state,
        screens: state.screens,
      };
      case DELETE_SCREEN:
        return {
          ...state,
          isScreenDeleted:true,
          screens:state.screens.filter(screen=>screen._id!==action.payload)
        }
    default:
      return state;
  }
};
