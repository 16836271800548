import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addScreenToPlaylist,
  deletePlaylist,
  playlistScreenCheck,
} from "../../../../Redux/Actions/playlistAction";
import { getScreens } from "../../../../Redux/Actions/screenAction";
import "./playlistcard.css";
import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
import { ReactComponent as CreateIcon } from "../../../../Assets/images/createicon.svg";
import { Box, Menu, MenuItem } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";

function PlaylistCard(props) {
  const dispatch = useDispatch();
  const { screens } = useSelector((state) => state.Screen);
  const [code, setCode] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
    // setOpenProfileDialog(true);
  };
  useEffect(() => {
    dispatch(getScreens());
    // console.log(props);
  }, []);
  const newCheck = (c) => {
    const id = setTimeout(() => {
      const screen = screens.find((s) => s._id === c);
      // console.log(screen);
      if (screen !== null) {
        props.socketRef.current?.emit("playlist_added", {
          detail: { mac: screen?.mac, code: c },
        });
        clearTimeout(id);
      }
    }, 2000);
  };
  return (
    <>
      <div className="playlist-cards">
        <div className="playlist-cards-top">
          <div className="playlist-schedule-icon flex justifyContentCenter alignItemCenter"></div>
          <div className="playlist-card-body">{props.children}</div>
        </div>
        <div className="playlist-cards-bottom flex justify-content-space-between alignItemCenter">
          <div className="add-content-playlist flex justifyContentCenter alignItemCenter">
            <CreateIcon
              style={{ color: "#551fff", cursor: "pointer" }}
              onClick={() =>
                props.addContentModel(
                  props.play._id,
                  props.play?.activescreen?.mac
                )
              }
            />
            <span>Create</span>
          </div>
          <Box>{props.play.name}</Box>
          <div className="delete-playlist flex justifyContentCenter alignItemCenter">
          <DeleteIcon
            onClick={() =>
              props.deleteWholePlaylist(
                props.play._id,
                props.play?.activescreen?.mac
              )
            }
          sx={{ color: "#551fff", cursor: "pointer" }} />
            {/* <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "40px",
                width: "25px",
                background: "rgba(97, 121, 251,.2)",
                borderRadius: "5px",
              }}
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup={"true"}
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn"
            >
              <MoreVertIcon sx={{ color: "#551fff" }} />
            </Box>
            <Menu
              id={"content-menu"}
              anchorEl={anchorEl}
              open={open}
              MenuListProps={{
                "aria-labelledby": "content-menu-btn",
              }}
              onClose={handleClose}
              keepMounted
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <MenuItem onClick={handleClose}>
                <Box
                  onClick={() =>
                    props.deleteWholePlaylist(
                      props.play._id,
                      props.play?.activescreen?.mac
                    )
                  }
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-start",
                  }}
                >
                  <DeleteIcon sx={{ color: "#551fff", cursor: "pointer" }} />
                  Delete
                </Box>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <Box
                  onClick={() => props.addSchedule(props?.play?._id)}
                  sx={{
                    display: "flex",
                    gap: "5px",
                    justifyContent: "flex-start",
                  }}
                >
                  <ScheduleSendIcon
                    sx={{ color: "#551fff", cursor: "pointer" }}
                  />
                  Schedule
                </Box>
              </MenuItem>
            </Menu> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default PlaylistCard;
