import {
  Checkbox,
  Container,
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./login.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch, useSelector } from "react-redux";
import { login, userFlagOff } from "../../Redux/Actions/userAction";
import { Box } from "@mui/system";
import loginBackgroundIMG from "../../Assets/images/login-background.png";
import Cookies from "js-cookie";
import toast from "react-hot-toast";
import axios from 'axios';
function Login() {
  const dispatch = useDispatch();
  const { auth, loginSuccess, failed, isLogging } = useSelector(
    (state) => state.User
  );
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [type, setType] = useState(true);
  const [rememberme, setRememberMe] = useState(false);
  
  useEffect(() => {
    if (loginSuccess) {
      toast.success("login success!");
      dispatch(userFlagOff());
    }
    if (failed) {
      toast.error("login failed!");
      dispatch(userFlagOff());
    }
  }, [loginSuccess, failed]);
  useEffect(() => {
    // socketRef.current.emit('leaving--connection')
    const isOk = Cookies.get("leedoitCredential");
    if (isOk !== undefined) {
      const d = JSON.parse(Cookies.get("leedoitCredential"));
      setEmail(d?.email);
      setPassword(d?.password);
      setRememberMe(true);
    } else {
      setPassword("");
      setEmail("");
      setRememberMe(false);
    }
  }, []);
  const submitLogin = () => {
    if (email === "") {
      return;
    }
    if (password === "") {
      return;
    }
    dispatch(login(email, password));
  };
  const checkedRememberMe = (e) => {
    const { checked } = e.target;
    if (checked) {
      setRememberMe(true);
      Cookies.set(
        "leedoitCredential",
        JSON.stringify({ email: email, password: password }),
        { expires: 7 }
      );
    } else {
      Cookies.remove("leedoitCredential");
      setRememberMe(false);
    }
  };
  if (auth) {
    return navigate("/");
  }
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          maxWidth: "100vw",
          backgroundImage: `url(${loginBackgroundIMG})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          position: "relative",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {
               isLogging && <>
                <Box
                sx={{
                  height: "100vh",
                  width: "100vw",
                  position: "absolute",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background:'rgba(0,0,0,0.5)',
                  top:0,
                  left:0,
                  bottom:0,
                  right:0,
                  zIndex:'100000'
                }}
              >
                <img src="/images/spiner.gif" />
              </Box>
               </>
             }
        <Container maxWidth="xl">
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                position: "relative",
              }}
            >
             
              <Box
                sx={{
                  width: { lg: "50%", md: "50%", sm: "73%", xs: "73%" },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="login-form">
                  <div className="login-form-header">
                    <div className="logo-sec">
                      <div className="logo">
                        <img src="/images/LEEDOIT.png" />
                      </div>
                    </div>
                  </div>
                  <div className="login-form-body">
                    <div className="title">
                      <div className="login-icon"></div>
                      <div className="title-text size-title-text-1">Login</div>
                    </div>
                    <div className="input-fields">
                      <TextField
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        type={"email"}
                        variant={"standard"}
                        label={"Email"}
                        style={{
                          borderBottom: "1px solid #551FFF",
                          color: "#551FFF",
                        }}
                        fullWidth
                      />
                    </div>
                    <div className="input-fields">
                      <FormControl variant={"standard"} fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                          id="password"
                          type={type ? "password" : "text"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          endAdornment={
                            <InputAdornment
                              position={"end"}
                              style={{ marginTop: "-10px", color: "#551fff" }}
                            >
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setType(!type)}
                              >
                                {type ? (
                                  <VisibilityOffIcon
                                    style={{ color: "#551fff" }}
                                  />
                                ) : (
                                  <VisibilityIcon
                                    style={{ color: "#551fff" }}
                                  />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </div>
                    <div className="other-controlls">
                      <div className="checkbox-div">
                        <Checkbox
                          checked={rememberme}
                          onChange={checkedRememberMe}
                          style={{
                            margin: "-10px 0",
                            borderRadius: "4px",
                          }}
                        />{" "}
                        Remember me
                      </div>
                      <Link to={'/forgetpassword'}>Forget Password?</Link>
                    </div>
                    <div className="button-field">
                      <button className="btn-login" onClick={submitLogin}>
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: { lg: "50%", md: "50%", sm: "0%", xs: "0%" },
                }}
              >
                <div className="text-block">
                  <p className="main-heading">
                    We Make Your Business Digitalize
                  </p>
                  <p className="sub-heading">
                    If you have question please contact support@leedoit.com?
                  </p>
                </div>
              </Box>
            </Box>
            <div className="bottom-box"></div>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default Login;
