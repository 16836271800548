import { VideoLabelSharp } from '@mui/icons-material';
import React from 'react'
import Chart from 'react-apexcharts';
function DonutChart({series,labels,views}) {
  return (
    <>
      <Chart
        style={{
          fontFamily: 'Open Sans !important',
        }}
        type={'donut'}
        // height={250}
        // width={250}
        series={series}
        options={
          {
            title: {
              text: views,
              style: { fontSize: 15 },
              color: '#15192C',
            },
            labels: labels,
            dataLabels: { enabled: true },
            legend: {
              position: 'left',
              show: false
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    total: {
                      showAlways: true,
                      show: true
                    }
                  }
                }
              }
            },
          }
        }
      >
      </Chart>
    </>
  )
}

export default DonutChart