import React, { useState } from "react";
import { imgUrl } from "../../../../api/api";
import { Menu, MenuItem } from "@mui/material";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { ReactComponent as DeleteIcon } from "../../../../Assets/images/delete.svg";
function PlaylistContentCardItem(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };
  return (
    <>
      {/* <div className="content-card-item">
        
     </div> */}
      <>
        <div className="content-card-item">
          <div className="content-card-item-body">
            <div className="upper-sec flex justify-content-space-between">
              <div
                className={`${
                  props.content.type !== "video" && "timer-background"
                } timer flex justifyContentCenter alignItemCenter`}
              >
                {props.content.type !== "video" && (
                  <>
                    <AccessTimeIcon
                      onClick={() =>
                        props.updateTimeHandler(
                          props.playID,
                          props.content._id,
                          props.content.duration
                          // play?.activescreen?.mac
                        )
                      }
                      sx={{
                        marginRight: "5px",
                      }}
                    />
                    <span>
                      {Math.floor((props.content?.duration / 1000 / 60) << 0)}:
                      {Math.floor((props.content?.duration / 1000) % 60)}
                    </span>
                  </>
                )}
              </div>

              <div className="delete-icon flex justifyContentCenter alignItemCenter">
                <MoreVertIcon
                  sx={{ fontSize: "25px", color: "#551FFF" }}
                  onClick={handleClick}
                  aria-controls={open ? "content-menu" : undefined}
                  aria-haspopup={"true"}
                  aria-expanded={open ? "true" : undefined}
                  id="content-menu-btn"
                />
                <Menu
                  id={"content-menu"}
                  anchorEl={anchorEl}
                  open={open}
                  MenuListProps={{
                    "aria-labelledby": "content-menu-btn",
                  }}
                  onClose={handleClose}
                  keepMounted
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <MenuItem
                    onClick={() =>
                      props.deleteContent(
                        props.playID,
                        props.content._id
                        // play?.activescreen?._id
                      )
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <DeleteIcon />
                  </MenuItem>
                  <MenuItem
                    onClick={() =>
                      props.addSchedule(props.playID, props.content._id)
                    }
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ScheduleSendIcon style={{ color: "#551FFF" }} />
                  </MenuItem>
                </Menu>
              </div>
            </div>
            {props.content.type === "image" && (
              <img src={`${imgUrl}/${props.content.url}`} alt="" />
            )}
            {props.content.type === "video" && (
              <video src={`${imgUrl}/${props.content.url}`} controls />
            )}
            {props.content.type === "url" && <iframe src={props.content.url} />}
          </div>
        </div>
      </>
    </>
  );
}

export default PlaylistContentCardItem;
