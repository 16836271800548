import { ContentReducer } from "./Redux/Reducers/contentReducer";
import { formReducer } from "./Redux/Reducers/formReducer";
import { screenReducer } from "./Redux/Reducers/screenReducer";
import { userReducer } from "./Redux/Reducers/userReducer";

const { combineReducers } = require("redux");
const { playlistReducer } = require("./Redux/Reducers/playlistReducer");

export const rootReducer=combineReducers({
    Playlist:playlistReducer,
    Screen:screenReducer,
    Content:ContentReducer,
    Form:formReducer,
    User:userReducer
})