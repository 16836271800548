import {io} from 'socket.io-client';
export const socketInit=()=>{
    const options={
        'force new connection':true,
        reconnection: true,          // Enable reconnection
  reconnectionDelay: 1000,
  reconnectionDelayMax: 1000,
        reconnectionAttempt:'Infinity',
        timeout:10000,
        transport:['websocket'],
    }
    return io('https://signage.digntec.com',options);
    // return io('http://localhost:5000',options);
    } 