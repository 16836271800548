import logo from './logo.svg';
import './App.css';
import toast, { Toaster } from 'react-hot-toast';
import { BrowserRouter, Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContent } from "./Redux/Actions/contentAction";
import { getUsers, login, logout, refreshLogin } from "./Redux/Actions/userAction";
import Content from "./views/content/Content";
import Login from "./views/login/Login";
import User from "./views/user/User";
import {
  getScreens,
  screenStatusActive,
  screenStatusDeActive,
} from "./Redux/Actions/screenAction";
import Screen from "./views/screen/Screen";
import Playlist from "./views/playlist/Playlist";
import { getPlaylist } from "./Redux/Actions/playlistAction";
import ForgetPassword from "./views/login/ForgetPassword";
import Dashboard from "./views/dashboard/Dashboard";
import Setting from "./views/setting/Setting";
import { socketInit } from './socket';
import PrivacyPolicy from './views/privacypolicy/PrivacyPolicy';
import ChangePassword from './views/login/ChangePassword';
import {useRefreshLogin} from './hooks/useRefreshLogin';
import { Box } from '@mui/material';
import loginBackgroundIMG from "./Assets/images/login-background.png";
import Sharing from './views/sharing/Sharing';
const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

function App() {
  const dispatch = useDispatch();
  const { user ,auth} = useSelector((state) => state.User);
  const {loading}=useRefreshLogin();
  
  let timer;
  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      logoutAction();
    }, 5*60*1000);
  };
  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };
  
  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
  }, []);
  const logoutAction = () => {
    // resetTimer();
    socketRef.current.emit('leaving--connection')
    dispatch(logout())
    // window.location.pathname = "/login";
  };


  const socketRef = useRef();
  useEffect(() => {
    const connection = () => {
      socketRef.current = socketInit();
// console.log(socketRef.current)
  //     socketRef.current.emit('join',
  //     {"detail":{"mac":"fab0614e168ed57d","device_details":{"ip":"192.168.232.2","height":1600,"width":2560,"os_version":"7.1.1","manufacture":"Google","device_name":"Google Android SDK built for x86","software":"1.0","root_level":true},"ram":{"total":"3038 MB","free":"2507 MB"},"storage":{"total":"2006 MB","free":"1771 MB"}}}
  // )
      // socketRef.current.emit('ActivateScreen',{codes:'7686',mac:'2b:3c:9d:12:yu'})
      // socketRef.current.emit('join',{"detail":{"mac":"fb43171416bda42e"}})
      // socketRef.current.on("screen--sharing--success",(obj)=>{
      //   console.log(obj)
      // })
      socketRef.current.on("screen", (data) => {
        // console.log(data);
      });
      socketRef.current.on('message_recieved',({message})=>{
        if(message){
          toast.success('message successfully')
        }
      })
      socketRef.current.on("admin_screen", ({ detail, left }) => {
        // console.log(detail);
        dispatch(screenStatusActive(detail.mac, true));
      });
      socketRef.current.on("leave", (obj) => {
        // console.log(obj);
        dispatch(screenStatusDeActive(obj.mac, false));
      });
      // socketRef.current.on('screen--sharing--success',({msg,screenShort})=>{
      //   console.log(msg,screenShort)
      // })
    };
    connection();
  }, []);
  useEffect(() => {
    dispatch(getContent(user?._id));
    dispatch(getScreens(user?._id));
    dispatch(getUsers(user?._id));
    dispatch(getPlaylist(user?._id));
    // dispatch(getPlaylist());
  }, []);
  return loading ? (<>
  <Box
   sx={{
    minHeight: "100vh",
    maxWidth: "100vw",
    backgroundImage: `url(${loginBackgroundIMG})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    position: "relative",
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
  }}
  >
<Box sx={{
  position:'absolute',
  top:0,
  right:0,
  bottom:0,
  left:0,
  display:'flex',
  justifyContent:'center',
  alignItems:'center'
}}>
   <img src='/images/spiner.gif'/>
</Box>
  </Box>
 
  </>) : (
    <div className="App">
    <Toaster
      position={"top-right"}
      toastOptions={{
        duration: 3000,
      }}
    />
    <BrowserRouter>
      <Routes>
        <Route exact path={"/changepassword/:id/:token"} element={<ChangePassword/>} />
        <Route exact path={"/forgetpassword"} element={<ForgetPassword />} />
        <Route exact path={'/privacypolicy'} element={<PrivacyPolicy/>}/>
        <Route exact path={"/login"} element={<Login />} />
        <Route element={<PrivateRouter/>}>
          <Route
            exact
            path={"/"}
            element={<Dashboard user={user} socketRef={socketRef} />}
          />
          <Route
            exact
            path={"/content"}
            element={<Content user={user} socketRef={socketRef} />}
          />
          <Route exact path={"/user"} element={<User user={user} socketRef={socketRef} />} />
          <Route
            exact
            path={"/screen"}
            element={<Screen user={user} socketRef={socketRef} />}
          />
          <Route
            exact
            path={"/playlist"}
            element={<Playlist user={user} socketRef={socketRef} />}
          />
          <Route
            exact
            path={"/setting/:id"}
            element={<Setting user={user} socketRef={socketRef} />}
          />
           <Route
            exact
            path={"/sharing/:id"}
            element={<Sharing user={user} socketRef={socketRef} />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  </div>
  );
}
function PrivateRouter() {
  const { auth } = useSelector((state) => state.User);
  return (
    <>
    {auth ? <Outlet /> : <Navigate to={'/login'} />}
    </>
  )
}
export default App;
